import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, IconButton, Box, Badge, Grid, Fab, TextField, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import UserNavbar from '../Components/UserNavbar';
import { ENDPOINT, Urls } from '../Network/Url';


const roleStyles = {
    1: { backgroundColor: '#ffcccc', color: '#ff0000', fontFamily: 'CustomFont' }, // admin
    2: { backgroundColor: '#789C6A57', color: '#789C6A', fontFamily: 'CustomFont' }, // bde
    3: { backgroundColor: '#769DB957', color: '#769DB9', fontFamily: 'CustomFont' }, // expert
    4: { backgroundColor: '#d9d9d9', color: '#808080', fontFamily: 'CustomFont' }, // expert manager
    5: { backgroundColor: '#D4A5A557', color: '#D4A5A5', fontFamily: 'CustomFont' }, // quality
    6: { backgroundColor: '#ccccff', color: '#000080', fontFamily: 'CustomFont' }, // project assigner
    7: { backgroundColor: '#D2B55B57', color: '#D2B55B', fontFamily: 'CustomFont' }, // Client
};

const roleMapping = {
    1: 'admin',
    2: 'bde',
    3: 'expert',
    4: 'expert manager',
    5: 'quality',
    6: 'project assigner',
    7: 'client',
};

export default function UserList() {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchUsers = async (searchQuery) => {
        setLoading(true);
        try {
            let token = localStorage.getItem("authToken");
            const response = await axios.post(`${ENDPOINT}/${Urls.userList}`,
                {
                    searchQuery: searchQuery || ""
                },
                {
                    headers: {
                        'authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            setUsers(response.data.Data.Users);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false); // Set loading to false after fetching data
        }
    };

    useEffect(() => {
        fetchUsers(searchTerm);
    }, [searchTerm]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // const filteredUsers = users.filter(user =>
    //     user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     user.userId.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    const handleRowClick = (user) => {
        navigate(`/user/${user.userId}`, { state: { user } });
    };

    const handleAddUserClick = () => {
        navigate('/add-user');
    };

    return (
        <>
            <UserNavbar />
            <Container maxWidth='none' sx={{ bgcolor: '#E8E8E8', border: "2px solid #656363" }}>
                <Box sx={{ my: 3 }}>
                    <Typography variant="h4" component="h1" gutterBottom sx={{
                        float: 'left',
                        fontFamily: 'CustomFont', fontSize: '23px', fontWeight: '700', lineHeight:
                            '39px'
                    }}>
                        All Users and Accesses
                    </Typography>
                    <TextField
                        variant="outlined"
                        placeholder="Search by ID or Name"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        size="small"
                        sx={{
                            float: 'right',
                            backgroundColor: 'white',
                            marginBottom: '16px',
                            width: '40%',
                            borderRadius: '25px',
                            height: '45px',
                            justifyContent: 'center'
                        }}
                        InputProps={{
                            style: { padding: '8px' },
                            sx: {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }
                        }}
                    />

                    {loading ? ( // Show loader while loading
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableBody>
                                    {users.map((user) => (
                                        <TableRow key={user.userId} sx={{
                                            lineHeight: '60px',
                                            cursor: 'pointer'
                                        }} onClick={() => handleRowClick(user)}>
                                            <TableCell component="th" scope="row" sx={{
                                                fontFamily:
                                                    'CustomFont', fontSize: '21px', fontWeight: '700',
                                                textAlign: 'left'
                                            }} >
                                                {user.name}
                                                <Typography variant="body2" color="textSecondary"
                                                    sx={{
                                                        fontFamily: 'CustomFont', fontSize: '15px',
                                                        fontWeight: '400', textAlign: 'left'
                                                    }}>
                                                    created
                                                    {new Date(user.createdAt).toLocaleDateString()}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{
                                                textAlign: 'center', fontFamily:
                                                    'CustomFont', fontSize: '21px', fontWeight: '700',
                                                color: 'black'
                                            }}>{user.userId}</TableCell>
                                            <TableCell sx={{
                                                textAlign: 'center', gap: '79px',
                                                display: 'flex', flexDirection: 'row-reverse',
                                                justifyContent: 'flex-start', alignItems: 'center'
                                            }}>
                                                <Grid>
                                                    <IconButton aria-label="edit" sx={{
                                                        background: '#c4c7ce', width: '42px',
                                                        height: '42px'
                                                    }}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid>
                                                    <Badge
                                                        badgeContent={roleMapping[user.usertype]}
                                                        sx={{
                                                            '& .MuiBadge-badge': {
                                                                ...roleStyles[user.usertype] ||
                                                                { backgroundColor: '#ccc', color: '#000' },
                                                                padding: '4px 8px',
                                                                borderRadius: '4px',
                                                                fontSize: '18px',
                                                                fontWeight: 'bold',
                                                                width: '127px',
                                                                height: '42px',
                                                                fontFamily: 'CustomFont'
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>
                <Fab color="primary" aria-label="add" sx={{
                    position: 'fixed', bottom: 16, right: 16, background: '#3C455E'
                }} onClick={handleAddUserClick}>
                    <AddIcon sx={{ width: '100%', height: '100%' }} />
                </Fab>
            </Container>
        </>
    );
}