import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Grid,
    Button,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentNavbar from './AssignmentNavbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { ENDPOINT, Urls } from '../Network/Url';
import toast, { Toaster } from 'react-hot-toast';

const UploadBox = styled(Box)(({ theme }) => ({
    border: '2px dashed #ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 150,
    cursor: 'pointer'
}));

function EditExpertWork() {
    const { state } = useLocation();
    const { assignment, expert } = state || {};
    const navigate = useNavigate();

    const token = localStorage.getItem("authToken");
    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    const userType = userData.usertype

    const [experts, setExperts] = useState({
        details: expert.details,
        projectStatus: expert.projectStatus,
        media: expert.media || [],
        uploadDocs: []
    });

    const [files, setFiles] = useState([]);
    const [fileData, setFileData] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setExperts((prev) => ({ ...prev, [name]: value }));
        console.log("name", name, value);
    };

    const handleFileChange = async (e) => {
        const newFiles = Array.from(e.target.files);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);

        for (const file of newFiles) {
            const formdata = new FormData();
            formdata.append("file", file);

            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    "authorization": `Bearer ${token}`,
                }),
                body: formdata,
                redirect: 'follow'
            };

            try {
                const response = await fetch(`${ENDPOINT}/${Urls.uploadFile}`, requestOptions);
                const result = await response.json();
                if (result.Success === 1) {
                    setFileData((prevData) => [...prevData, { name: file.name, link: result.Data.url }]);
                } else {
                    console.error('Failed to upload file:', result.Message);
                    toast.error(result.Message);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };


    const handleRemoveFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setFileData((prevData) => prevData.filter((_, i) => i !== index)); // Remove URL and name as well
    };


    const handleDeleteDoc = (index) => {
        setExperts((prev) => ({
            ...prev,
            media: prev.media.filter((_, i) => i !== index)
        }));
    };

    const handleSave = async () => {
        if (!token) {
            console.error("Token not found in localStorage");
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            details: experts.details || '',
            projectStatus: experts.projectStatus,
            assignmentId: assignment.assignment_id,
            media: [...experts.media, ...fileData] || experts.uploadDocs.map(file => file.name) || [],
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        try {
            console.log("Sending request with payload:", raw);
            const response = await fetch(`${ENDPOINT}/${Urls.updateExpertWork}/${expert.id}`, requestOptions);

            if (!response.ok) {
                const errorMessage = await response.text();
                console.error('HTTP error:', response.status, errorMessage);
                return;
            }

            const result = await response.json();
            console.log('Result:', result);

            if (result.Success) {
                console.log('Assignment updated successfully:', result);
                toast.success(result.Message);
                navigate(-1);
            } else {
                console.error('Error updating assignment:', result.Message);
                toast.error(result.Message);
            }
        } catch (error) {
            console.error('Error updating assignment:', error);
        }
    };

    const statusOptions = [
        { value: 1, label: 'created' },
        { value: 2, label: 'assigned' },
        { value: 3, label: 'qc-passed' },
        { value: 4, label: 'qc-rejected' },
        { value: 5, label: 'completed' },
        { value: 6, label: 'delivered' },
        { value: 7, label: 'client-rejected' }
    ];

    const getStatusOptions = (userType) => {
        switch (userType) {
            case 1:
                return statusOptions.filter(option => option.value === 1 || option.value === 2 || option.value === 3 || option.value === 4 || option.value === 5 || option.value === 6 || option.value === 7);
            case 3:
                return statusOptions.filter(option => option.value === 5 || option.value === 2);
            case 5:
                return statusOptions.filter(option => option.value === 3 || option.value === 4);

            // Add more cases here for different userType values and their respective status options
            default:
                return statusOptions;
        }
    };

    const filteredStatusOptions = getStatusOptions(userType);

    console.log(experts, "quality")

    return (
        <>
            <AssignmentNavbar />
            <Toaster />
            <Box p={2} sx={{ background: '#E8E8E8' }}>
                <Typography variant="h6"
                    sx={{
                        textAlign: 'left',
                        ml: 2,
                        fontFamily: 'CustomFont',
                        fontWeight: 700,
                        fontSize: '22px',
                        lineHeight: '39px'
                    }}>
                    Edit Assignment
                </Typography>
                <Box sx={{ background: '#fff', borderRadius: 2, p: 3, mt: 2 }}>
                    <Grid container spacing={2} sx={{ mt: 2, textAlign: 'left' }}>
                        <Grid item xs={12}>
                            <TextField
                                label="Details"
                                fullWidth
                                multiline
                                rows={4}
                                name="details"
                                value={experts.details ? experts.details : ""}
                                disabled={userType === 5}
                                onChange={handleChange}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontFamily: 'CustomFont', // Your custom font here
                                        fontWeight: 500,
                                        fontSize: '17px',
                                        lineHeight: '28px',
                                        color: '#1D1B20'
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontFamily: 'CustomFont', // Custom font for the label
                                        color: '#1D1B20'
                                    },
                                }}
                            />
                        </Grid>
                        {experts.media.length > 0 && (
                            <>
                                <Typography variant="h6" sx={{
                                    fontFamily: 'CustomFont',
                                    fontWeight: 700,
                                    fontSize: '21px',
                                    lineHeight: '24px',
                                    color: '#000000',
                                    mt: 2,
                                    width: '100%',
                                    ml: 2
                                }}>Uploaded Docs
                                </Typography>
                                <Box sx={{ display: 'grid', ml: 2 }}>
                                    {experts.media.map((doc, index) => (
                                        <React.Fragment key={index}>
                                            <div style={{ display: 'flex' }}>
                                                <Typography variant="body1" sx={{
                                                    mt: 1,
                                                    fontFamily: 'CustomFont',
                                                    fontWeight: 500,
                                                    color: '#7C7C7C',
                                                    fontSize: '18px',
                                                    lineHeight: '20px',
                                                }}>{doc.name}</Typography>
                                                <IconButton aria-label="delete" disabled={userType === 5} onClick={() => handleDeleteDoc(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </Box>
                            </>
                        )}

                        <Grid item xs={12} sx={{ textAlign: 'left' }}>
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="upload-docs"
                                multiple
                                type="file"
                                onChange={handleFileChange}
                                disabled={userType === 5}
                            />
                            <label htmlFor="upload-docs" style={{ display: 'contents' }}>
                                <UploadBox sx={{ width: '10%' }}>
                                    <AddIcon fontSize="large" />
                                </UploadBox>
                            </label>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                {files.map((file, index) => (
                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                                        <Typography variant="body1">{file.name}</Typography>
                                        <IconButton aria-label="delete" onClick={() => handleRemoveFile(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 3 }}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="status-label" sx={{
                                fontFamily: 'CustomFont',
                                color: '#1D1B20'
                            }}>Status</InputLabel>
                            <Select
                                labelId="status-label"
                                label="Status"
                                name="projectStatus"
                                value={experts.projectStatus}
                                onChange={handleChange}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontFamily: 'CustomFont',
                                        fontWeight: 500,
                                        fontSize: '17px',
                                        lineHeight: '28px',
                                        color: '#1D1B20',
                                        textAlign: 'left'
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontFamily: 'CustomFont',
                                        color: '#1D1B20'
                                    },
                                }}
                            >
                                {filteredStatusOptions.map((option) => (
                                    <MenuItem sx={{
                                        fontFamily: 'CustomFont',
                                        fontWeight: 500,
                                        fontSize: '17px',
                                        lineHeight: '28px',
                                        color: '#1D1B20',
                                    }} key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={11}>
                                <Button variant="contained" sx={{
                                    width: '186px',
                                    height: '40px',
                                    borderRadius: '10px',
                                    background: '#3C455E'
                                }} onClick={handleSave}>
                                    <Typography sx={{
                                        fontFamily: 'CustomFont',
                                        fontWeight: 700,
                                        textTransform: 'capitalize',
                                        fontSize: '19px',
                                        lineHeight: '24px',
                                        color: 'white'
                                    }}>
                                        Save
                                    </Typography>
                                </Button>
                            </Grid>
                            {/* <Grid item xs={1} sx={{ alignContent: 'center' }}>
                                <Typography variant="contained" color="#3C455E" sx={{ cursor: 'pointer' }} onClick={handleDelete}>
                                    Delete
                                </Typography>
                            </Grid> */}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default EditExpertWork;
