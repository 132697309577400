import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Card, CardContent, Grid, Button, Fab, TextField, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, FormControl, InputLabel, Select, MenuItem, TextareaAutosize, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Filter from '../../Components/Assets/images/lgbtn.svg'
import { useNavigate } from 'react-router-dom';
// import { DatePicker } from '@mui/lab';
import DatePicker from 'react-datepicker';
import DownloadIcon from "@mui/icons-material/Download";
import 'react-datepicker/dist/react-datepicker.css';
import { ENDPOINT, Urls } from '../../Network/Url';
import Navbar from './DashboardNavbar';


function AllAssignments() {
    const navigate = useNavigate();
    const location = useLocation();
    const projectStatusFromState = location.state?.projectStatus || 'all';
    console.log("BBB", projectStatusFromState);
    const [assignments, setAssignments] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [showPayment, setShowPayment] = useState(false);
    const [showClient, setShowClient] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [isDownload, setIsDownload] = useState('')

    const [hardDeadline, setHardDeadline] = useState(null);
    const [softDeadline, setSoftDeadline] = useState(null);
    const [bdeOptions, setBdeOptions] = useState([]);
    const [expertOptions, setExpertOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [subjectOptions, setSubjectOptions] = useState([]);  // Initialized as an array
    const [expertManagerOptions, setExpertManagerOptions] = useState([]);
    const [projectAssignerOptions, setProjectAssignerOptions] = useState([]);
    const [qualityOptions, setQualityOptions] = useState([]);

    const [clientId, setClientId] = useState('');
    const [bdeId, setBdeId] = useState('');
    const [expertManagerId, setExpertManagerId] = useState('');
    const [openPay, setOpenPay] = useState(false);
    const [subject, setSubject] = useState('');
    const [quality, setquality] = useState('');
    const [projectStatus, setProjectStatus] = useState(projectStatusFromState)

    const [openFilterDialog, setOpenFilterDialog] = useState(false);


    const statusOptions = [
        { value: 1, label: 'created' },
        { value: 2, label: 'assigned' },
        { value: 3, label: 'qc-passed' },
        { value: 4, label: 'qc-rejected' },
        { value: 5, label: 'completed' },
        { value: 6, label: 'delivered' },
        { value: 7, label: 'client-rejected' }
    ];

    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    const { name, usertype } = userData;

    const handleAddUserClick = () => {
        navigate('/add-assignment');
    };

    const token = localStorage.getItem("authToken");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${ENDPOINT}/${Urls.assignmentUser}`, {
                    method: 'GET',
                    headers: {
                        'authorization': `Bearer ${token}`
                    }
                });
                const result = await response.json();
                if (result.Success) {
                    const combinedOptions = [
                        ...result.Data.bde,
                        ...result.Data['expert-manager'],
                        ...result.Data['project-assigner']
                    ];
                    setBdeOptions(combinedOptions);
                    setExpertOptions(result.Data.expert);
                    setClientOptions(result.Data.client);
                    setExpertManagerOptions(result.Data['expert-manager']);
                    setProjectAssignerOptions(result.Data['project-assigner']);
                    setQualityOptions(result.Data.quality);
                    setSubjectOptions(Array.isArray(result.Data.subjects) ? result.Data.subjects : []);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleSearchChange = (event) => {
        const search = event.target.value;
        setSearchTerm(search);
        fetchAssignments(1, search, clientId, bdeId, expertManagerId, quality, projectStatus, hardDeadline, softDeadline, subject);
    };

    const handleFilterDialogOpen = () => {
        setOpenFilterDialog(true);
    };

    const handleFilterDialogClose = () => {
        setOpenFilterDialog(false);
    };

    const handleFilterClose = () => {
        setClientId('');
        setSubject('');
        setBdeId('');
        setExpertManagerId('');
        setquality('');
        setProjectStatus('');
        setHardDeadline(null);
        setSoftDeadline(null);
        fetchAssignments(1, searchTerm, '', '', '', '', '', null, null);

    };

    const handleApplyFilters = () => {
        fetchAssignments(1, searchTerm, clientId, bdeId, expertManagerId, quality, projectStatus, hardDeadline, softDeadline, subject);
        setOpenFilterDialog(false);
    };

    const cleanFilters = (filters) => {
        const cleaned = {};
        for (const key in filters) {
            if (filters[key] !== null && filters[key] !== '' && (typeof filters[key] !== 'object' ||
                Object.keys(cleanFilters(filters[key])).length !== 0)) {
                cleaned[key] = filters[key];
            }
        }
        return cleaned;
    };

    const fetchAssignments = async (page, search, clientId, bdeId, expertManagerId, quality, projectStatus, hardDeadline, softDeadline, subject) => {
        try {
            // Adjust filter based on projectStatus
            const filters = {
                client: clientId,
                bde: bdeId,
                expertManager: expertManagerId,
                quality: quality,
                status: projectStatus !=="all" ? projectStatus : "",
                subject: subject,
                createdDateRange: {
                    start: hardDeadline ? hardDeadline.toISOString().split('T')[0] : null,
                    end: softDeadline ? softDeadline.toISOString().split('T')[0] : null
                }
            };

            const cleanedFilters = cleanFilters(filters);

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    pageno: page,
                    count: 10,
                    search: search,
                    filters: cleanedFilters

                }),
                redirect: 'follow'
            };

            const response = await fetch(`${ENDPOINT}/${Urls.assignments}`, requestOptions);
            if (!response.ok) {
                throw new Error('Failed to fetch assignments');
            }

            const data = await response.json();
            console.log(data);
            if (data.Success === 1) {
                setAssignments(data.Data.assignments);
                setTotalCount(data.Data.stats.total_assignments);
                setTotalPages(Math.ceil(data.Data.stats.total_assignments / 10));
                setShowPayment(data.Data.settings.showPayment);
                setIsDownload(1);
                // Set other state variables as needed
            } else {
                throw new Error(data.Message);
            }
        } catch (error) {
            console.error('Error fetching assignments:', error);
            // Handle error state or alert user
        }
    };


    useEffect(() => {
        // Check if projectStatus is set and fetch accordingly
        if (projectStatus) {
            fetchAssignments(currentPage, searchTerm, clientId, bdeId, expertManagerId, quality, projectStatus, hardDeadline, softDeadline, subject);
        }
    }, [currentPage, searchTerm, clientId, bdeId, expertManagerId, quality, projectStatus, hardDeadline, softDeadline, subject]);



    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        boxShadow: 'none'
    }));

    const handleRowClick = (assignment) => {
        // if (assignment.projectStatus === 1) {
        //     navigate(`/assignment-detail`, { state: { assignment } });
        // } else {
            navigate(`/client-summary`, { state: { assignment } });
        // }
    };

    const formatDateTime = (dateString) => {
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'UTC',
        };
        return new Date(dateString).toLocaleString('en-IN', options).replace(',', ' ');
    };

    const renderPaymentStatusButton = (paymentStatus) => {
        switch (paymentStatus) {
            case 1:
                return <Typography className='payment' variant="h6" sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#9B111E57', color: '#9B111E' }}>due</Typography>;
            case 2:
                return <Typography className='payment' variant="h6" sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#31006257', color: '#310062' }}>partial</Typography>;
            case 3:
                return <Typography className='payment' variant="h6" sx={{
                    fontFamily: 'CustomFont',
                    fontSize: '18px',
                    fontWeight: '700',
                    color: '#006A4E',
                    background: '#006A4E57'
                }}>paid</Typography>;
            default:
                return null;
        }
    };

    const renderProjectStatusButton = (projectStatus) => {
        switch (projectStatus) {
            case 1:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#00BFFF57', color: '#00BFFF' }}>created</Typography>;
            case 2:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#B7410E57', color: '#B7410E' }}>on-going</Typography>;
            case 3:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#00315357', color: '#003153' }}>qc-passed</Typography>;
            case 4:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#D2B55B57', color: '#D2B55B' }}>qc-rejected</Typography>;
            case 5:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#556B2F57', color: '#556B2F' }}>completed</Typography>;
            case 6:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#70421457', color: '#704214' }}>delivered</Typography>;
            case 7:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#D0312D4D', color: '#D0312D', width: 'fit-content' }}>client-rejected</Typography>;
            default:
                return null;
        }
    };

    const renderAssignments = () => {
        let filteredAssignments = assignments;

        if (projectStatus === 2) {
            filteredAssignments = assignments.filter(assignment => assignment.projectStatus === 2);
        } else if (projectStatus === 5) {
            filteredAssignments = assignments.filter(assignment => assignment.projectStatus === 5);
        }

        if (filteredAssignments.length === 0) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
            );
        }

        return filteredAssignments.map((assignment) => (
            <Card key={assignment.assignment_id} variant="notchedOutline" sx={{ cursor: 'pointer' }} onClick={() => handleRowClick(assignment)}>
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                    <Grid item xs={12} md={4}>
                        <Item sx={{ textAlign: 'left' }}>
                            <Typography variant="h6" sx={{ fontFamily: 'CustomFont', fontWeight: '700', color: 'black' }}>
                                {assignment.title} ({assignment.number_of_words ? assignment.number_of_words : '--'} words)
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Created by {assignment.createdBy.name}, {formatDateTime(assignment.createdTime)}
                            </Typography>
                            {showClient &&
                                <Typography variant="body2" color="textSecondary">
                                    Client: {assignment.clientName ? assignment.clientName : "--"}
                                </Typography>
                            }
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Item>
                            <Typography variant="h6" sx={{ fontFamily: 'CustomFont', fontWeight: '700', color: 'black' }}>ZOM-{assignment.assignment_id}</Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Item sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '10px' }}>
                            {showPayment && <Typography sx={{ fontFamily: 'CustomFont' }} variant="h6">₹{assignment.budget ? assignment.budget : "--"}</Typography>}
                            {showPayment && renderPaymentStatusButton(assignment.paymentStatus)}
                            {renderProjectStatusButton(assignment.projectStatus)}
                        </Item>
                    </Grid>
                </Grid>
                <hr style={{ width: '99%' }} />
            </Card>
        ));
    };


    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleClose = () => {
        setOpenPay(false);
    };

    const handleButtonClick = () => {
        const pageNo = currentPage; // Or derive this value as needed
        const search = searchTerm; // Assuming searchTerm is a state variable holding the search value
        const download = isDownload

        const filters = {
            client: clientId,
            bde: bdeId,
            expertManager: expertManagerId,
            quality: quality,
            status: projectStatus !=="all" ? projectStatus : "",
            subject: subject,
            createdDateRange: {
                start: hardDeadline ? hardDeadline.toISOString().split('T')[0] : null,
                end: softDeadline ? softDeadline.toISOString().split('T')[0] : null
            }
        };

        handleClickOpen(pageNo, search, download, filters);
    };

    const handleClickOpen = (pageNo, search, download = '', filters = {}) => {
        console.log(`Page Number: ${pageNo}, Search Term: ${search}`);
        const myHeaders = new Headers();
        myHeaders.append("authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const cleanedFilters = cleanFilters(filters);

        const raw = JSON.stringify({
            "pageno": pageNo,
            "count": 10,
            "search": search,
            "isDownload": download,
            "filters": cleanedFilters
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${ENDPOINT}/assignment/assignments`, requestOptions)
            .then((response) => {
                if (response.ok) {
                    return response.blob(); // Use blob to handle binary data
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((blob) => {
                // Create a link element to download the file
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'assignments.xlsx'; // Name of the downloaded file
                document.body.appendChild(a);
                a.click();
                a.remove(); // Clean up
                window.URL.revokeObjectURL(url); // Release memory
            })
            .catch((error) => console.error('There was a problem with the fetch operation:', error));

    };

    return (
        <div>
            <Navbar />
            <Box p={2} sx={{ background: '#E8E8E8' }}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h4" component="div" sx={{ flexGrow: 1, display: 'flex' }}>
                        All Assignments
                    </Typography>
                    {[1, 2, 3, 4, 5, 6, 7].includes(usertype) && (
                        <TextField
                            variant="outlined"
                            placeholder="Search by ID or Name"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            size="small"
                            autoComplete="off"
                            sx={{
                                float: 'right',
                                backgroundColor: 'white',
                                width: '25%',
                                borderRadius: '25px',
                                height: '45px',
                                justifyContent: 'center'
                            }}
                            InputProps={{
                                sx: {
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                }
                            }}
                        />
                    )}

                    {[1, 2].includes(usertype) && (
                        <input
                            style={{
                                width: '105px',
                                height: '45px',
                                paddingLeft: '20px'
                            }}
                            type="image"
                            id="image"
                            alt="Filter"
                            src={Filter}
                            onClick={handleFilterDialogOpen}
                        />
                    )}

                    {[1, 2, 3, 4].includes(usertype) && (
                        <IconButton
                            sx={{
                                background: "#3C455E",
                                color: "white",
                                width: '3%',
                                marginLeft: '10px',
                                "&:hover": { background: "#3C455E", color: "white" },
                            }}
                            onClick={handleButtonClick}
                        >
                            <DownloadIcon />
                        </IconButton>
                    )}
                    {/* <Button onClick={handleFilterClose}>Remove Filter</Button> */}

                </div>

                <Box mt={2}>
                    {renderAssignments()}
                </Box>
                <Box textAlign="center" mt={2}>
                    <Button
                        sx={{ background: '#3C455E', fontFamily: 'CustomFont', mr: 1 }}
                        variant="contained"
                        color="primary"
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                    <Button
                        sx={{ background: '#3C455E', fontFamily: 'CustomFont' }}
                        variant="contained"
                        color="primary"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </Box>
                <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16, background: '#3C455E' }}
                    onClick={handleAddUserClick}>
                    <AddIcon sx={{ width: '100%', height: '100%' }} />
                </Fab>
            </Box>

            <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
                <DialogTitle sx={{ alignSelf: 'center', fontFamily: 'CustomFont', fontSize: '22px', fontWeight: 700 }}>
                    Filter
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ background: '#FFFFFF', width: '100%' }}>

                        <Grid item xs={12} sx={{ textAlign: 'left' }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="client-label">Client</InputLabel>
                                <Select
                                    labelId="client-label"
                                    label="Client"
                                    value={clientId}
                                    onChange={(e) => setClientId(e.target.value)}
                                >
                                    {clientOptions.map(client => (
                                        <MenuItem key={client.userId} value={client.userId}>{client.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'left' }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="subject-list-label">Subject List</InputLabel>
                                <Select
                                    labelId="subject-list-label"
                                    label="Subject List"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                >
                                    {subjectOptions.map(subject => (
                                        <MenuItem key={subject} value={subject}>{subject}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'left' }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="bde-label">BDE</InputLabel>
                                <Select
                                    labelId="bde-label"
                                    label="BDE"
                                    value={bdeId}
                                    onChange={(e) => setBdeId(e.target.value)}
                                >
                                    {bdeOptions.map(bde => (
                                        <MenuItem key={bde.userId} value={bde.userId}>{bde.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'left' }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="expert-manager-label">Expert Manager</InputLabel>
                                <Select
                                    labelId="expert-manager-label"
                                    label="Expert Manager"
                                    value={expertManagerId}
                                    onChange={(e) => setExpertManagerId(e.target.value)}
                                >
                                    {expertManagerOptions.map(manager => (
                                        <MenuItem key={manager.userId} value={manager.userId}>{manager.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'left' }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="Quality-list-label">Quality</InputLabel>
                                <Select
                                    labelId="Quality-list-label"
                                    label="Quality List"
                                    value={quality}
                                    onChange={(e) => setquality(e.target.value)}
                                >
                                    {qualityOptions.map(quality => (
                                        <MenuItem key={quality.userId} value={quality.userId}>{quality.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: 1 }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="status-label" sx={{
                                    fontFamily: 'CustomFont',
                                    color: '#1D1B20'
                                }}>Status</InputLabel>
                                <Select
                                    labelId="status-label"
                                    label="Status"
                                    name="projectStatus"
                                    value={projectStatus}
                                    onChange={(e) => setProjectStatus(e.target.value)}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: 'CustomFont',
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20',
                                            textAlign: 'left'
                                        },
                                        '& .MuiInputLabel-root': {
                                            fontFamily: 'CustomFont',
                                            color: '#1D1B20'
                                        },
                                    }}
                                >
                                    {statusOptions.map((option) => (
                                        <MenuItem sx={{
                                            fontFamily: 'CustomFont',
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20',
                                        }} key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <div style={{ marginTop: '32px', display: 'flex', gap: '20px', margin: '16px' }}>
                            <Grid item xs={12}>
                                <DatePicker
                                    selected={hardDeadline}
                                    onChange={(date) => setHardDeadline(date)}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Hard Deadline"
                                    customInput={<TextField label="Hard Deadline" fullWidth variant="outlined" />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker
                                    selected={softDeadline}
                                    onChange={(date) => setSoftDeadline(date)}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Soft Deadline"
                                    customInput={<TextField label="Soft Deadline" fullWidth variant="outlined" />}
                                />
                            </Grid>
                        </div>
                    </Grid>
                    <DialogActions sx={{ justifyContent: 'center', mt: 2 }}>
                        <Grid item xs={12} textAlign={'-webkit-center'}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ display: 'block', width: '16%', padding: '10px', background: '#3C455E', color: 'white', fontFamily: 'CustomFont' }}
                                onClick={handleApplyFilters}
                            >
                                Filter
                            </Button>
                            <Button onClick={handleFilterClose}>Remove Filter</Button>
                        </Grid>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default AllAssignments;
