const isProduction = 1;

const PRODUCTION_ENDPOINT = "https://api.zomak.in/v1";
const STAGING_ENDPOINT = "http://3.70.100.29:2021/v1";

export const ENDPOINT = isProduction ? PRODUCTION_ENDPOINT : STAGING_ENDPOINT;

export const Urls = {
    login: "user/login",
    forgotPassword: 'user/forgot-password',
    getNotifications:'notification/get-notifications',
    getProfile:'user/my-profile',
    updateProfile: 'user/update-profile',
    userList: 'user/get-users',
    createUser:"user/create-user",
    editUser:"user/edit-user",
    changePassword:"user/change-password",
    deleteUser:"user/delete-user",
    uploadFile: "user/upload-file",
    getAttendance: "attendance/get-attendance",
    checkIn: "attendance/check-in",
    checkOut: "attendance/check-out",
    assignments: "assignment/assignments",
    addAssignment: "assignment/create-assignment",
    assignmentUser: "assignment/all-users?userTypes=bde,expert,client,expert-manager,project-assigner,quality",
    assignmentDetail: "assignment/get-assignment",
    addPayment: "assignment/add-payment",
    removePayment: "assignment/remove-payment",
    addExpert: "assignment/add-expert",
    removeExpert: "assignment/remove-expert",
    exportAttendance: "attendance/export-attendance",
    getAllLogs: "assignment/get-all-logs",
    addComment: "assignment/add-comment",
    updateExpertWork: "assignment/update-expert-work",
    editAssignemt: "assignment/edit-assignment"
}


