import React, { useState, useEffect} from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import toast, { Toaster } from "react-hot-toast";
import { ENDPOINT, Urls } from "../Network/Url";

const UserProfile = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user } = state || {};
  const [newname, setnewname] = useState(user?.name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(user?.phone_number || "");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(true);


  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  const { name, usertype, userId} = userData;

  // Define the role mapping
  const roleMapping = {
    1: "admin",
    2: "bde",
    3: "expert",
    4: "expert manager",
    5: "quality",
    6: "project assigner",
    7: "client",
  };

  const rolenewname = roleMapping[usertype] || "user";

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      let token = localStorage.getItem("authToken");

      myHeaders.append("authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(`${ENDPOINT}/${Urls.getProfile}`, requestOptions);
        const result = await response.json();
        if(result.Success){
          setnewname(result.Data.name);
          setEmail(result.Data.email);
          setPhoneNumber(result.Data.phone_number);
        } else {
          toast.error(result.Message);
        }
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        toast.error("Failed to fetch user data");
        setLoading(false);  // Set loading to false even if there is an error
      }};
      fetchData();
    }, [userId]);

 
  const handleSaveProfile = async (event) => {
    event.preventDefault();

    const myHeaders = new Headers();

    let token = localStorage.getItem("authToken");

    myHeaders.append("authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    let formattedPhoneNumber = phoneNumber.trim(); // Remove leading and trailing spaces

    // Check if the phone number doesn't start with a plus sign
    if (!formattedPhoneNumber.startsWith("+")) {
      formattedPhoneNumber = "+" + formattedPhoneNumber; // Add plus sign
    }

    const raw = JSON.stringify({
      userId: userId,
      phoneNumber: formattedPhoneNumber,
      name: newname,
      userType: usertype,
      email: email,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${ENDPOINT}/${Urls.editUser}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        toast.success(result.Message, {
          icon: "👏",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("Update failed!");
      });
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();

    const myHeaders = new Headers();

    let token = localStorage.getItem("authToken");

    myHeaders.append("authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      oldPassword: oldPassword,
      newPassword: newPassword,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${ENDPOINT}/${Urls.changePassword}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        toast.success(result.Message, {
          icon: "👏",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("Update failed!");
      });
  };

  return (
    <div>
      <AppBar position="static" sx={{ backgroundColor: "#3C455E" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontFamily: 'CustomFont', flexGrow: 1, textAlign: "center" }}>
            Profile
          </Typography>
        </Toolbar>
      </AppBar>
      <Toaster />
      <Box p={5} sx={{ background: "#E8E8E8", height: "100vh" , }}>
        <Typography
          variant="h6"
          sx={{
              fontFamily: "CustomFont",
              fontSize: "23px",
              fontWeight: "700",
              lineHeight: "39px",
              textAlign:"left",
              my:1,
            }}
        >
          {name}- {rolenewname}
        </Typography>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
            p: 5,
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
            }}
          ></Box>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            required
            value={newname}
            onChange={(e) => setnewname(e.target.value)}
          />
          <TextField
            label="E-mail"
            variant="outlined"
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
            <PhoneInput
              country={"in"}
              inputProps={{
                newname: "phoneNumber",
                id: "phoneInput",
                classnewname: "form-control",
                style: { width: "100%", fontSize: "Large" },
              }}
              value={phoneNumber}
              onChange={(value) => setPhoneNumber(value)}
              specialLabel={null}
            />
          </Box>
          <Button
            onClick={handleSaveProfile}
            variant="contained"
            sx={{
              background: "#3C455E",
              mt: 2,
              width: "20%",
              justifySelf: "center",
              fontFamily: "CustomFont",
              fontSize: "19px",
              lineHeight: "24px",
              fontWeight: "700",
              letterSpacing: "0.5px",
            }}
          >
            Save
          </Button>

          <TextField
            label="Old Password"
            variant="outlined"
            fullWidth
            required
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <TextField
            label="New Password"
            variant="outlined"
            fullWidth
            required
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Button
            onClick={handleChangePassword}
            variant="contained"
            sx={{
              background: "#3C455E",
              mt: 2,
              width: "20%",
              justifySelf: "center",
              fontFamily: "CustomFont",
              fontSize: "19px",
              lineHeight: "24px",
              fontWeight: "700",
              letterSpacing: "0.5px",
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default UserProfile;
