import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, MenuItem, Select, InputLabel, FormControl, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UserNavbarUpdate from './UserNavbarUpdate';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import toast, { Toaster } from 'react-hot-toast';
import { ENDPOINT, Urls } from '../Network/Url';

const AddUser = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [usertype, setUsertype] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleBack = () => {
    navigate(-1);
  };

  const userData = JSON.parse(localStorage.getItem('userData')) || {};
  console.log("mmm", userData);

  useEffect(() => {
    // Set default user type to "Client" if the current user is not an Admin
    if (userData.usertype !== 1) {
      setUsertype(7);
    }
  }, [userData.usertype]);

  const handleSave = (event) => {
    event.preventDefault();

    // Validation check
    if (!name || !email || !phoneNumber || !usertype) {
      toast.error('Please fill in all fields before saving.', {
        icon: '⚠️',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
      return;
    }

    let token = localStorage.getItem("authToken");

    const myHeaders = new Headers();
    myHeaders.append("authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    let formattedPhoneNumber = phoneNumber.trim(); // Remove leading and trailing spaces

    // Check if the phone number doesn't start with a plus sign
    if (!formattedPhoneNumber.startsWith('+')) {
      formattedPhoneNumber = '+' + formattedPhoneNumber; // Add plus sign
    }

    const raw = JSON.stringify({
      "email": email,
      "password": "123456",  // Use a proper password input in a real app
      "phone_number": formattedPhoneNumber,
      "name": name,
      "usertype": usertype
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${ENDPOINT}/${Urls.createUser}`, requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(result);
        toast.success('User Created Successfully!', {
          icon: '👏',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
        setTimeout(() => {
          navigate('/users');
        }, 2000);
      })
      .catch(error => {
        console.log('error', error);
        toast.error('User Creation Failed!', {
          icon: '⚠️',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
      });
  };

  return (
    <div>
      <UserNavbarUpdate />
      <Toaster />
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 4,
          mx: 2,
          gap: 2
        }}
      >
        <div style={{ width: '100%', display: 'flex' }}>
          <Typography variant="h5">Add New User</Typography>
        </div>
        
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="E-mail"
          variant="outlined"
          fullWidth
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Box sx={{ display: 'flex', gap: 1, width: '100%' }}>
          <PhoneInput
            country={'nl'}
            inputProps={{
              name: 'phoneNumber',
              id: 'phoneInput',
              className: 'form-control',
              style: { width: '100%', fontSize: 'Large' }
            }}
            value={phoneNumber}
            onChange={(value) => setPhoneNumber(value)}
            specialLabel={null}
          />
        </Box>
        <FormControl fullWidth required>
          <InputLabel>Select Type</InputLabel>
          <Select
            label="Select Type"
            value={usertype}
            onChange={(e) => setUsertype(e.target.value)}
            sx={{ textAlign: 'left' }}
            disabled={userData.usertype !== 1}
          >
            <MenuItem value={1}>Admin</MenuItem>
            <MenuItem value={2}>BDE</MenuItem>
            <MenuItem value={3}>Expert</MenuItem>
            <MenuItem value={4}>Expert Manager</MenuItem>
            <MenuItem value={5}>Quality</MenuItem>
            <MenuItem value={6}>Project Assigner</MenuItem>
            <MenuItem value={7}>Client</MenuItem>
          </Select>
        </FormControl>
        <Button onClick={handleSave} variant="contained" sx={{ background: '#3C455E', mt: 2 }}>
          Save
        </Button>
      </Box>
    </div>
  );
};

export default AddUser;
