import "./ForgetPassword.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../Components/Assets/images/zomakLogo.svg";
import { TextField, Button, Grid } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {IconButton,} from "@mui/material";
import { ENDPOINT, Urls } from "../Network/Url";
import axios from "axios";



function ForgetPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: email,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${ENDPOINT}/${Urls.forgotPassword}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        setMessage("New Password has been sent to your email.");
        toast.success("New Password has been sent to your email.");
        navigate("/");
      })
      .catch((error) => {
        console.error(error);
        setMessage("An error occurred. Please try again.");
        toast.error("An error occurred. Please try again.");
      });
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="closebutton">
        <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
            <ArrowBackIosIcon />
          </IconButton>
          </div>
        <div className="inner-login">
          <img src={Logo} alt="Zomak Logo" className="logo" />
          <h2
            style={{
              fontFamily: "CustomFont",
              fontSize: "25px",
              fontWeight: "700",
              letterSpacing: "0.5px",
              lineHeight: "24px",
            }}
          >
            Forgot Password
          </h2>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-email"
                  onChange={handleEmailChange}
                  label="E-mail/UserId"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    style: {
                      fontSize: "18px", // Change font size
                      fontFamily: "CustomFont",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <button
                  InputLabelProps={{
                    style: {
                      fontSize: "18px",
                      fontFamily: "CustomFont",
                    },
                  }}
                  className="login-button"
                  onClick={handleSubmit}
                >
                  Send Password
                </button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
