import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import Bell from '../Components/Assets/images/bell.png';

const Navbar = () => {
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const handleSidebarToggle = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Retrieve and parse the user data from localStorage
  const userData = JSON.parse(localStorage.getItem('userData')) || {};
  const { name, usertype } = userData;

  // Define the role mapping
  const roleMapping = {
    1: 'admin',
    2: 'bde',
    3: 'expert',
    4: 'expert manager',
    5: 'quality',
    6: 'project assigner',
    7: 'client',
  };

  // Determine the role name based on the usertype
  const roleName = roleMapping[usertype] || 'user';

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: '#3C455E' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleSidebarToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ fontFamily: 'CustomFont', flexGrow: 1 }}>
            Zomak Solutions
          </Typography>
          <div> <img src={Bell} alt="Bellicon" className="BellLogo"  onClick={()=>{navigate('/notification')}} style={{width: '30px', height: '35.68px', top: '30px', left: '1190px', padding:'15px', cursor:'pointer'}}></img> </div>

          <div>
            <Typography variant="h6" onClick={handleMenuClick} style={{ cursor: 'pointer', display: 'grid', width: '100%', justifyItems: 'start' }}>
              <span style={{ fontFamily: 'CustomFont', fontSize: '22px', fontWeight: '700', lineHeight: '29px' }} >{name}</span>
              <span style={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '400', lineHeight: '24px' }}>as {roleName}</span>
            </Typography>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => {
                navigate('/attendance')
              }}>Attendance</MenuItem>
              <MenuItem onClick={() => {navigate('/profile')
              }}>Profile</MenuItem>
              <MenuItem onClick={() => {
                localStorage.removeItem("authToken");
                localStorage.removeItem("userData");
                navigate("/");
              }}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarToggle} />
    </Box>
  );
}

export default Navbar;

