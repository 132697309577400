import React, { useEffect, useState } from 'react';
import { Typography, Grid, Box, Button, Paper, IconButton, Chip, Divider, TextField, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ClientNavbar from './ClientNavbar';
import { styled } from '@mui/material/styles';
import { ENDPOINT, Urls } from '../../Network/Url';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Edit from '../../Components/Assets/images/edit.svg'
import DeleteIcon from '@mui/icons-material/Delete';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import DownloadIcon from '@mui/icons-material/Download';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: 'none'
}));

const AssignmentSummary = () => {

    const { state } = useLocation();
    const { assignment, notificationDetails } = state || {};
    const navigate = useNavigate();
    const token = localStorage.getItem("authToken");
    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    const userType = userData.usertype;

    const assignmentId = assignment?.assignment_id || notificationDetails?.assignmentId;

    const [expertOptions, setExpertOptions] = useState([]);
    const [expertId, setExpertId] = useState([]);
    const [qualityOptions, setQualityOptions] = useState([]);
    const [qualityId, setQualityId] = useState('');
    const [assignmentDetail, setAssignmentDetail] = useState({ docs: [] });
    const [experts, setExperts] = useState([]);
    const [qualityStatus, setQualityStatus] = useState(0);
    const [payments, setPayments] = useState()
    const [paymentAmount, setPaymentAmount] = useState('');
    const [modeOfPayment, setModeOfPayment] = useState('');
    const [showHeadDeadLine, setShowHeadDeadLine] = useState(0);
    const [selectedExperts, setSelectedExperts] = useState([]);
    const [showPayment, setShowPayment] = useState(0);
    const [showClient, setShowClient] = useState(0);
    const [isCanUpdate, setIsCanUpdate] = useState(0);
    const [isCanEdit, setIsCanEdit] = useState(0);
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(true);

    console.log(assignmentDetail, "experts")

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handleCommentSubmit = async () => {
        const myHeaders = new Headers();
        myHeaders.append("authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            assignmentId: assignment.assignment_id,
            comment: comment
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${ENDPOINT}/${Urls.addComment}`, requestOptions);
            const result = await response.text();
            console.log(result);
            toast.success(result.Message);
            setComment("");
            window.location.reload();
        } catch (error) {
            console.error(error);
            toast.error("Failed to add comment");
        }
    };


    const renderProjectStatusButton = (projectStatus) => {
        switch (projectStatus) {
            case 1:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#00BFFF57', color: '#00BFFF' }}>created</Typography>;
            case 2:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#B7410E57', color: '#B7410E' }}>on-going</Typography>;
            case 3:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#00315357', color: '#003153' }}>qc-passed</Typography>;
            case 4:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#D2B55B57', color: '#D2B55B' }}>qc-rejected</Typography>;
            case 5:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#556B2F57', color: '#556B2F' }}>completed</Typography>;
            case 6:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#70421457', color: '#704214' }}>delivered</Typography>;
            case 7:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#D0312D4D', color: '#D0312D', width: 'fit-content' }}>client-rejected</Typography>;
            default:
                return null;
        }
    };

    const statusMap = {
        1: "created",
        2: "assigned",
        3: "qc-passed",
        4: "qc-rejected",
        5: "completed",
        6: "delivered",
        7: "client-rejected"
    };

    const [timeline, setTimeline] = useState([]);

    useEffect(() => {
        if (assignmentId) {
            const myHeaders = new Headers();
            myHeaders.append("authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            fetch(`${ENDPOINT}/${Urls.getAllLogs}/${assignmentId}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const data = result.Data.map(log => {
                        let action = '';
                        console.log(log, "log")
                        switch (log.logType) {
                            case 1:
                                action = log.comment ? log.comment : 'Added a comment';
                                break;
                            case 2:
                                action = (
                                    <>
                                        changed main assignment status to <strong>{statusMap[log.status]}</strong>
                                    </>
                                );
                                break;
                            case 3:
                                action = `changed ${log.aassignmentExpertInfo != null ? log.aassignmentExpertInfo.name : ""} assignment status to ${statusMap[log.status] || log.status}`;
                                break;
                            default:
                                action = 'performed an action';
                        }

                        return {
                            user: log.userInfo.name,
                            action,
                            date: new Date(log.createdAt).toLocaleString(),
                            media: log.media,
                            userType: log.userInfo.usertype
                        };
                    });
                    setTimeline(data);
                })
                .catch(error => console.error("There was an error fetching the data!", error));
        } else {
            console.warn("No assignment ID or notification ID provided.");
            setLoading(false);
        }
    }, [assignmentId, token]);

    const fetchData = async (id) => {
        try {
            setLoading(true);
            const response = await fetch(`${ENDPOINT}/${Urls.assignmentDetail}/${id}`, {
                method: 'GET',
                headers: {
                    'authorization': `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (result.Success) {
                setAssignmentDetail({
                    ...result.Data,
                    docs: result.Data.docs || [], // Ensure docs is an array
                });
                setSelectedExperts(result.Data.experts === null ? [] : result.Data.experts);
                setPayments(result.Data.payments);
                setQualityId(result.Data.quality.userId === null ? "" : result.Data.quality.userId);
                setShowPayment(result.Data.showPayment);
                setShowClient(result.Data.showClient);
                setIsCanUpdate(result.Data.isCanUpdate);
                setIsCanEdit(result.Data.isCanEdit);
                setExperts(result.Data.experts === null ? [] : result.Data.experts);
                setShowHeadDeadLine(result.Data.showHeadDeadLine);
                setQualityStatus(result.Data.projectStatus);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (assignmentId) {
            fetchData(assignmentId);
        } else {
            console.warn("No assignment ID or notification ID provided.");
            setLoading(false);
        }
    }, [assignmentId, token]);

    const formatDateTime = (dateString) => {
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'UTC',
        };
        return new Date(dateString).toLocaleString('en-IN', options).replace(',', ' ');
    };

    const formatDateTimeLine = (dateString) => {
        // Split the date and time parts
        const [datePart, timePart] = dateString.split(', ');

        // Split the date part into day, month, and year
        const [day, month, year] = datePart.split('/').map(Number);

        // Split the time part into hours, minutes, and seconds
        const [hour, minute] = timePart.split(':').map(Number);

        // Create a new Date object in UTC
        const date = new Date(Date.UTC(year, month - 1, day, hour + 13, minute));

        // Define options for toLocaleString
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };

        // Return the formatted date string in the desired locale
        return date.toLocaleString('en-IN', options);
    };


    const handleExpert = (expert) => {
        navigate('/edit-expert-work', { state: { assignment, expert } })
    }

    function removeDuplicateEvents(events) {
        const seen = new Set();
        return events.filter(event => {
            const identifier = `${event.user}-${event.date}`;
            if (seen.has(identifier)) {
                return false;
            } else {
                seen.add(identifier);
                return true;
            }
        });
    }

    const filteredTimeline = removeDuplicateEvents(timeline);

    const downloadAllFiles = async () => {
        const zip = new JSZip();

        for (const doc of assignmentDetail.docs) {
            try {
                const response = await fetch(doc.url);
                if (!response.ok) {
                    throw new Error(`Failed to fetch ${doc.url}: ${response.status} ${response.statusText}`);
                }
                const blob = await response.blob();
                zip.file(doc.name, blob);
            } catch (error) {
                console.error('Error fetching file:', error);
                // Handle error gracefully, e.g., notify user or retry later
            }
        }

        zip.generateAsync({ type: 'blob' })
            .then(blob => {
                saveAs(blob, 'files.zip');
            })
            .catch(error => {
                console.error('Error generating zip:', error);
            });
    };

    const downloadDocFiles = async (expert) => {
        const zip = new JSZip();

        for (const doc of expert.media) {
            try {
                const response = await fetch(doc.link);
                if (!response.ok) {
                    throw new Error(`Failed to fetch ${doc.link}: ${response.status} ${response.statusText}`);
                }
                const blob = await response.blob();
                zip.file(doc.name, blob);
            } catch (error) {
                console.error('Error fetching file:', error);
                // Handle error gracefully, e.g., notify user or retry later
            }
        }

        zip.generateAsync({ type: 'blob' })
            .then(blob => {
                saveAs(blob, 'files.zip');
            })
            .catch(error => {
                console.error('Error generating zip:', error);
            });
    };

    const [openPay, setOpenPay] = useState(false);

    const handleClickOpen = () => {
        setOpenPay(true);
    };

    const handleClose = () => {
        setOpenPay(false);
    };

    // const handleSavePayment = () => {
    //     var myHeaders = new Headers();
    //     myHeaders.append("authorization", `Bearer ${token}`);
    //     myHeaders.append("Content-Type", "application/json");

    //     var raw = JSON.stringify({
    //         "assignmentId": assignment.assignment_id,
    //         "client": assignment.client_id,
    //         "amount": paymentAmount, // Use the state variable here
    //         "mode_of_payment": modeOfPayment // Use the state variable here
    //     });

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: 'follow'
    //     };

    //     fetch(`${ENDPOINT}/${Urls.addPayment}`, requestOptions)
    //         .then(response => response.text())
    //         .then(result => {
    //             console.log(result); // Handle success response (optional)
    //             toast.success(result.Message);
    //             handleClose(); // Close dialog after successful save
    //             window.location.reload();
    //             // You may want to update state or trigger a refresh of data here
    //         })
    //         .catch(error => console.log('error', error));
    // };

    // const handleRemovePayment = (paymentId) => {
    //     var myHeaders = new Headers();
    //     myHeaders.append("authorization", `Bearer ${token}`);
    //     myHeaders.append("Content-Type", "application/json");

    //     var raw = JSON.stringify({
    //         "assignmentId": assignment.assignment_id,
    //         "paymentId": paymentId
    //     });

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: 'follow'
    //     };

    //     fetch(`${ENDPOINT}/${Urls.removePayment}`, requestOptions)
    //         .then(response => response.text())
    //         .then(result => {
    //             console.log(result);
    //             window.location.reload(); // Log or handle successful removal
    //             // You may want to update state or trigger a refresh of data here
    //         })
    //         .catch(error => console.log('error', error));
    // };

    // const handleChange = async (event) => {
    //     const { name, value } = event.target;

    //     // Update the state
    //     setQualityStatus((prev) => ({
    //         ...prev,
    //         [name]: value,
    //     }));

    //     // Prepare headers
    //     const myHeaders = new Headers();
    //     myHeaders.append("authorization", `Bearer ${token}`);
    //     myHeaders.append("Content-Type", "application/json");

    //     // Prepare request body
    //     const raw = JSON.stringify({
    //         projectStatus: value
    //     });

    //     // Request options
    //     const requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: "follow"
    //     };

    //     // Fetch API call
    //     try {
    //         const response = await fetch(`${ENDPOINT}/${Urls.editAssignemt}/${assignment.assignment_id}`, requestOptions);
    //         const result = await response.text();
    //         console.log(result);
    //         fetchData();
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };

    // const handledetails = () => {
    //     navigate(`/assignment-detail`, { state: { assignment } });
    // };


    const statusOptions = [
        { value: 1, label: 'created' },
        { value: 2, label: 'assigned' },
        { value: 3, label: 'qc-passed' },
        { value: 4, label: 'qc-rejected' },
        { value: 5, label: 'completed' },
        { value: 6, label: 'delivered' },
        { value: 7, label: 'client-rejected' }
    ];

    const getStatusOptions = (userType) => {
        switch (userType) {
            case 1:
                return statusOptions.filter(option => option.value === 1 || option.value === 2 || option.value === 3 || option.value === 4 || option.value === 5 || option.value === 6 || option.value === 7);
            case 3:
                return statusOptions.filter(option => option.value === 5 || option.value === 2);
            // case 5:
            //     return statusOptions.filter(option => option.value === 3 || option.value === 4);

            // Add more cases here for different userType values and their respective status options
            default:
                return statusOptions;
        }
    };

    const filteredStatusOptions = getStatusOptions(userType);

    const loader = (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
        </Box>
    );

    if (loading) {
        return loader; // Show loader while fetching data
    }

    return (
        <>
            <ClientNavbar />
            <Box pt={2} pb={2} sx={{ background: '#E8E8E8', textAlign: 'left' }}>
                <div style={{ display: 'flex', paddingBottom: '9px' }}>
                    <Typography variant="h4" gutterBottom
                        sx={{
                            paddingLeft: '20px',
                            fontFamily: 'CustomFont',
                            fontWeight: 700,
                            fontSize: '22px',
                            width: '50%'
                        }}>
                        Assignment - ZOM-{assignmentDetail.assignment_id ? assignmentDetail.assignment_id : "--"}
                    </Typography>
                </div>

                <Paper sx={{ p: 2, mb: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8} sx={{ position: 'relative', left: '18px' }}>
                            <Typography variant="body1" sx={{
                                fontFamily: 'CustomFont',
                                fontWeight: 700,
                                fontSize: '21px' // Use the medium font weight defined in your CSS
                            }}>
                                Title
                            </Typography>

                            <Typography variant="h5" sx={{
                                fontFamily: 'CustomFont',
                                fontWeight: 500,
                                fontSize: '21px' // Use the medium font weight defined in your CSS
                            }}>
                                {assignmentDetail.title ? assignmentDetail.title : "--"}
                            </Typography>
                            <Chip label=
                                {
                                    assignmentDetail.projectStatus
                                        ? renderProjectStatusButton(assignmentDetail.projectStatus)
                                        : "--"
                                }
                                sx={{
                                    mt: 1,
                                    background: 'none',
                                    textAlign: 'center',
                                    display: 'contents'
                                }}
                            />

                            <Grid item xs={12} md={1}>
                                <Item>
                                    <Typography variant="body2" sx={{
                                        fontFamily: 'CustomFont',
                                        fontWeight: 700,
                                        fontSize: '21px',
                                        lineHeight: '24px',
                                        color: '#000000'
                                    }}>Subject</Typography>
                                    <Typography variant="body1" sx={{
                                        fontFamily: 'CustomFont',
                                        fontWeight: 500,
                                        fontSize: '21px',
                                        lineHeight: '24px'
                                    }}>
                                        {assignmentDetail.subject ? assignmentDetail.subject : "--"}
                                    </Typography>
                                </Item>
                            </Grid>

                            <Typography variant="h6" sx={{
                                fontFamily: 'CustomFont',
                                fontWeight: 700,
                                fontSize: '21px',
                                lineHeight: '24px',
                                color: '#000000',
                                mt: 6
                            }}  >Details</Typography>
                            <Typography variant="body1" sx={{
                                fontFamily: 'CustomFont',
                                fontWeight: 500,
                                color: '#7C7C7C',
                                fontSize: '18px',
                                lineHeight: '20px',
                                width: '98%'
                            }}>
                                {assignmentDetail.details}
                            </Typography>

                            {assignmentDetail && assignmentDetail.docs && Array.isArray(assignmentDetail.docs) && assignmentDetail.docs.length > 0 ? (
                                <>
                                    <Typography variant="h6" sx={{
                                        fontFamily: 'CustomFont',
                                        fontWeight: 700,
                                        fontSize: '21px',
                                        lineHeight: '20px',
                                        color: '#000000',
                                        mt: 5
                                    }}>
                                        Uploaded Docs
                                        <IconButton
                                            onClick={downloadAllFiles}
                                            sx={{
                                                background: '#3C455E', color: 'white', width: '30px', height: '30px', ml: 1,
                                                '&:hover': { background: '#3C455E', color: 'white' }
                                            }}
                                        >
                                            <DownloadIcon />
                                        </IconButton>
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        {assignmentDetail.docs.map((doc, index) => (
                                            <Typography variant="body1" key={index} sx={{
                                                mt: 1, fontFamily: 'CustomFont',
                                                fontWeight: 500,
                                                color: '#7C7C7C',
                                                fontSize: '18px',
                                                lineHeight: '20px',
                                            }}>
                                                {doc.name}
                                            </Typography>
                                        ))}
                                    </Box>
                                </>
                            ) : (
                                <Typography variant="body1" sx={{
                                    mt: 1, fontFamily: 'CustomFont',
                                    fontWeight: 500,
                                    color: '#7C7C7C',
                                    fontSize: '18px',
                                    lineHeight: '24px',
                                }}>
                                    No documents available.
                                </Typography>
                            )}

                            <Typography variant="h6" sx={{
                                fontFamily: 'CustomFont',
                                fontWeight: 700,
                                fontSize: '21px',
                                lineHeight: '24px',
                                color: '#000000',
                                mt: 4,
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center'
                            }}>
                                Solutions Provided
                            </Typography>

                            {experts.map((expert, index) => (
                                <div key={index} style={{ marginTop: '12px' }}>
                                    {expert && expert.media && (
                                        <>
                                            <Typography variant="h6" sx={{
                                                fontFamily: 'CustomFont',
                                                fontWeight: 700,
                                                fontSize: '21px',
                                                lineHeight: '20px',
                                                color: '#000000',

                                            }}>
                                                Docs
                                                <IconButton
                                                    onClick={() => downloadDocFiles(expert)}
                                                    sx={{
                                                        background: '#3C455E', color: 'white', width: '30px', height: '30px', ml: 1,
                                                        '&:hover': { background: '#3C455E', color: 'white' }
                                                    }}
                                                >
                                                    <DownloadIcon />
                                                </IconButton>
                                            </Typography>
                                        </>
                                    )}

                                    <Grid container spacing={1} sx={{ display: 'flex', flexDirection: 'column' }} >
                                        {expert.media && expert.media.map((mediaItem, mediaIndex) => (
                                            <Grid item xs={3} key={mediaIndex}>
                                                <Grid>
                                                    <span style={{
                                                        mt: 1, fontFamily: 'CustomFont',
                                                        fontWeight: 500,
                                                        color: '#7C7C7C',
                                                        fontSize: '18px',
                                                        lineHeight: '20px',
                                                    }}>{mediaItem.name}</span>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>

                                    <Chip label=
                                        {
                                            expert.projectStatus
                                                ? renderProjectStatusButton(expert.projectStatus)
                                                : "--"
                                        }
                                        sx={{
                                            mt: 1,
                                            background: 'none',
                                            textAlign: 'center',
                                            display: 'contents',
                                            mt: 1
                                        }}
                                    />
                                    <Divider sx={{ my: 1, width: '96%' }} />
                                </div>
                            ))}

                            {assignmentDetail.qualityComment ?
                                <Chip
                                    label="completed"
                                    color="success"
                                /> : ""}
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </>
    );
};

export default AssignmentSummary;
