import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import Homeicon from '../Components/Assets/images/zomakLogo.svg';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';

const Sidebar = ({ isOpen, onClose }) => {
    const sidebarRef = useRef(null);

    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    const userType = userData.usertype

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    return (
        <div className={`sidebar ${isOpen ? 'open' : ''}`} ref={sidebarRef}>
            {isOpen && (
                <>
                    <img
                        className="image"
                        src={Homeicon}
                        alt='home'
                        onClick={onClose} // Close sidebar on image click
                    />
                    <div className="options">
                        <Link to="/dashboard"> <HomeIcon /> Home</Link>
                        {(userType === 1 || userType === 2 || userType === 4 || userType === 6) && (
                            <Link to="/Users"><PersonIcon /> Users</Link>
                        )}
                        <Link to="/all-assignment"><DescriptionIcon />Assignments</Link>
                    </div>
                </>
            )}
        </div>
    );
};

export default Sidebar;