import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Sidebar from './Sidebar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { useNavigate } from 'react-router-dom';

export default function MenuAppBar() {
    const [auth, setAuth] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const navigate = useNavigate();

    const handleSidebarToggle = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleHome = () => {
        navigate('/dashboard')
    };

    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    const { name, usertype } = userData;

    // Define the role mapping
    const roleMapping = {
        1: 'admin',
        2: 'bde',
        3: 'expert',
        4: 'expert manager',
        5: 'quality',
        6: 'project assigner',
        7: 'client',
    };

    // Determine the role name based on the usertype
    const roleName = roleMapping[usertype] || 'user';

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{ backgroundColor: '#3C455E' }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => navigate(-1)}
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ fontFamily: 'CustomFont', flexGrow: 1 }}>
                        Assignments
                    </Typography>
                    <div style={{display: 'flex', columnGap: '7px'}}>
                    <HomeRoundedIcon style={{ width: "43px", height: "52px", cursor: 'pointer' }} onClick={handleHome} />
                        {/* <Typography variant="h6" onClick={handleMenuClick} style={{ cursor: 'pointer', display: 'grid', width: '100%', justifyItems: 'start' }}>
                            <span style={{ fontFamily: 'CustomFont', fontSize: '22px', fontWeight: '700', lineHeight: '29px' }} >{name}</span>
                            <span style={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '400', lineHeight: '24px' }}>as {roleName}</span>
                        </Typography> */}
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={() => {
                                navigate('/attendance')
                            }}>Attendance</MenuItem>
                            <MenuItem onClick={() => {
                                navigate('/profile')
                            }}>Profile</MenuItem>
                            <MenuItem onClick={() => {
                                localStorage.removeItem("authToken");
                                localStorage.removeItem("userData");
                                navigate("/");
                            }}>Logout</MenuItem>
                        </Menu>                    
                        <Typography variant="h6" onClick={handleMenuClick} style={{ cursor: 'pointer', display: 'grid', width: '100%', justifyItems: 'start' }}>
                            <span style={{ fontFamily: 'CustomFont', fontSize: '22px', fontWeight: '700', lineHeight: '29px' }} >{name}</span>
                            <span style={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '400', lineHeight: '24px' }}>as {roleName}</span>
                        </Typography>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={() => {
                                navigate('/attendance')
                            }}>Attendance</MenuItem>
                            <MenuItem onClick={() => { navigate('/profile') }}>Profile</MenuItem>
                            <MenuItem onClick={() => {
                                localStorage.removeItem("authToken");
                                localStorage.removeItem("userData");
                                navigate("/");
                            }}>Logout</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            {/* <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarToggle} /> */}
        </Box>
    );
}
