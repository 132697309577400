import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import { Card, Grid, Typography, IconButton, Box } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import toast, { Toaster } from "react-hot-toast";
import { ENDPOINT, Urls } from "../Network/Url";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useLocation } from "react-router-dom";

export default function Notifications() {
  const { state } = useLocation();
  const { notifiacationDetails } = state || {};
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");

  const [notifications, setNotifications] = useState([]);
  const [logType, setLogType] = useState("");
  const [assignmentId, setAssignmentId] = useState("");
  const [status, setStatus] = useState("");
  const [userWhoDidUsername, setuserWhoDidUsername] = useState("");
  const [date, setTimeCreated] = useState("");
  const [comment, setComment] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [assignmentExpertInfo, setassignmentExpertInfo]=useState([]);
  console.log(assignmentExpertInfo, "info");

  const fetchNotifications = async (page) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({
            "pageno": page,
            "count": 50
        }),
      };

      const response = await fetch(
        `${ENDPOINT}/${Urls.getNotifications}`,
        requestOptions
      );

      if (!response.ok) {
        const responseText = await response.text();

        throw new Error(
          `Failed to fetch Notifications: ${response.statusText}`
        );
      }

      const data = await response.json();
      console.log("response data",data.Data);
      if (data.Success === 1) {
        setNotifications(data.Data);
        setassignmentExpertInfo(data.Data.assignmentExpertInfo===null ? [] : data.Data.assignmentExpertInfo);
        throw new Error(data.Message);
      }
    } catch (error) {
      console.error("Error fetching Notification:", error);
    }
  };

  useEffect(() => {
    fetchNotifications(currentPage);
  }, [currentPage]);

const handleRowClick = (notification) => {
    navigate(`/assignment-summary`, { state: { notificationDetails : notification} });
}


  const getNotificationMessage = (
    logType,
    comment,
    userWhoDidUsername,
    assignmentId,
    status,
    assignmentExpertInfoName,
    assignmentExpertInfoUsertype
  ) => {
    switch (logType) {
      case 1:
        return (
            <>
                <Typography component="span" variant="body1" sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700'}}>{userWhoDidUsername}</Typography>{' has commented at'}<Typography component="span" variant="body1" sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700'}}>Assignment ZOM-{assignmentId}</Typography>
            </>
        );
    case 2:
        return (
            <>
                <Typography component="span" variant="body1" sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700'}}>{userWhoDidUsername}</Typography> {'has changed'} <Typography component="span" variant="body1" sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#00BFFF57' }}> Assignment ZOM-{assignmentId} </Typography> {'status to'} <Typography component="span" variant="body1" sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#00BFFF57' }}> {renderProjectStatusButton(status)}</Typography>
            </>
        );
    case 3:
        return (
            <>
                <Typography component="span" variant="body1" sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700' }}>{assignmentExpertInfoName} ({renderExpertStatusButton(assignmentExpertInfoUsertype)})</Typography> {'work'} <Typography component="span" variant="body1" sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700' }}>Assignment ZOM-{assignmentId}</Typography> {'is updated'} <Typography component="span" variant="body1" sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700' }}></Typography> {'to'}<Typography component="span" variant="body1" sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700' }}> {renderProjectStatusButton(status)}</Typography> {'by'}  <Typography component="span" variant="body1" sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700' }}>{userWhoDidUsername}</Typography>
            </>
        );
    case 4:
        return (
            <>
               <Typography component="span" variant="body1" sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700'}}>Assignment ZOM-{assignmentId}</Typography> {'is assigned to you'}
            </>
        );
    default:
        return '';

    }
  };

  const renderProjectStatusButton = (projectStatus) => {
    switch (projectStatus) {
      case 1:
        return (
            'created'
        );
      case 2:
        return (
            'on-going'
        );
      case 3:
        return (
            'qc-passed'
        );
      case 4:
        return (
            'qc-rejected'
        );
      case 5:
        return (
            'completed'
        );
      case 6:
        return (
            'delivered'
        );
      case 7:
        return (
            'client-rejected'
        );
      default:
        return null;
    }
  };

  const renderExpertStatusButton = (Status) => {
    switch (Status) {
      case 1:
        return (
            "admin"
        );
      case 2:
        return (
            "bde"
        );
      case 3:
        return (
            "expert"
        );
      case 4:
        return (
            "expert manager"
        );
      case 5:
        return (
            "quality"
        );
      case 6:
        return (
            "project assigner"
        );
      case 7:
        return (
            "client"
        );
      default:
        return null;
    }
  };

  const formatDateTime = (dateString) => {
    const options = {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC",
    };
    return new Date(dateString)
      .toLocaleString("en-IN", options)
      .replace(",", " ");
  };

  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  const { name, usertype } = userData;

  const roleMapping = {
    1: "admin",
    2: "bde",
    3: "expert",
    4: "expert manager",
    5: "quality",
    6: "project assigner",
    7: "client",
  };
  const roleName = roleMapping[usertype] || "user";

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#3C455E" }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => navigate("/dashboard")}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontFamily: "CustomFont", flexGrow: 1 }}
          >
            Notifications
          </Typography>

          <div>
            <Typography
              variant="h6"
              style={{ fontFamily: "CustomFont", cursor: "pointer" }}
            >
              {name} as {roleName}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <Toaster />
      <Box p={4} sx={{ background: "#E8E8E8", height: "100%", }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
            p: 5,
            gap: 2,
          }}
        >
         {notifications.map((notification, index) => (
            <Box sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', textAlign:"left", width: "100%", cursor:"pointer"}} key={index} >
              <Typography onClick={() => handleRowClick(notification)}>
                {getNotificationMessage(
                  notification.logType,
                  notification.comment,
                  notification.userWhoDidUsername,
                  notification.assignmentId,
                  notification.status,
                  notification.assignmentExpertInfo === null ? "":  notification.assignmentExpertInfo.name,
                  notification.assignmentExpertInfo=== null ? "": notification.assignmentExpertInfo.usertype,
                  
                )}
              </Typography>
              <Typography variant="caption" sx={{ color: "gray" }}>
                {formatDateTime(notification.timeCreated)}
              </Typography>
              <hr style={{ width: "100%" }} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

