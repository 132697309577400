import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Components/Login';
import './App.css';
import ForgetPassword from './Components/ForgetPassword';
import Profile from './Components/Profile';
import Notification from './Components/Notifications';
import Dashboard from './Components/Dashboard';
import Navbar from './Components/Navbar';
import UserList from './Components/User';
import AddUser from './Components/AddUser';  // Import the AddUser component
import UserDetails from './Components/UserDetails';
import AttendancePage from './Components/Attendance';
import AddAssignment from './Components/AddAssignment';
import AllAssignment from './Components/AllAssignments';
import AssignmentDetail from './Components/AssignmentDetails';
import EditAssignment from './Components/EditAssignment';
import AssignmentSummary from './Components/AssignmentSummary';
import EditExpertWork from './Components/EditExpertWork';
import ClientDashboard from './Components/ClientPages/ClientDashboard';
import ClientSummary from './Components/ClientPages/ClientSummary';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/navbar" element={<Navbar />} />
          <Route path="/users" element={<UserList />} />
          <Route path="/add-user" element={<AddUser />} />
          <Route path="/user/:userId" element={<UserDetails />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/attendance" element={<AttendancePage />} />
          <Route path="/add-assignment" element={<AddAssignment />} />
          <Route path="/all-assignment" element={<AllAssignment />} />
          <Route path="/assignment-detail" element={<AssignmentDetail />} />
          <Route path="/edit-assignment" element={<EditAssignment />} />
          <Route path="/assignment-summary" element={<AssignmentSummary />} />
          <Route path="/edit-expert-work" element={<EditExpertWork />} />
          <Route path="/c-dashboard" element={<ClientDashboard />} />
          <Route path="/client-summary" element={<ClientSummary />} />
          {/* Add more routes here if needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;