import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Paper,
  MenuItem,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Grid,
  IconButton,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import UserNavbar from "../Components/UserNavbar";
import PhoneInput from "react-phone-input-2";
import toast, { Toaster } from "react-hot-toast";
import UserNavbarUpate from "./UserNavbarUpdate";
import { ENDPOINT, Urls } from "../Network/Url";
import dayjs from "dayjs";

const roleMapping = {
  1: "admin",
  2: "bde",
  3: "expert",
  4: "expert manager",
  5: "quality",
  6: "project assigner",
  7: "client",
};

export default function UserDetails() {
  const { state } = useLocation();
  const { user } = state || {};
  const navigate = useNavigate();
  const [name, setName] = useState(user?.name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [usertype, setUsertype] = useState(user?.usertype || "");
  const [phoneNumber, setPhoneNumber] = useState(user?.phone_number || "");

  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  const userType = userData.usertype;
  const isEditable = userData.usertype === 1;
  const isDeletable = userData.usertype === 1;


  const DownloadAttendanceDialog = ({
    open,
    handleClose,
    user,
    handleDownload,
  }) => {
    const [fromDate, setFromDate] = useState(dayjs());
    const [toDate, setToDate] = useState(dayjs());
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  const handleSave = (event) => {
    event.preventDefault();

    const myHeaders = new Headers();

    let token = localStorage.getItem("authToken");

    myHeaders.append("authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    let formattedPhoneNumber = phoneNumber.trim(); // Remove leading and trailing spaces

    // Check if the phone number doesn't start with a plus sign
    if (!formattedPhoneNumber.startsWith("+")) {
      formattedPhoneNumber = "+" + formattedPhoneNumber; // Add plus sign
    }

    const raw = JSON.stringify({
      userId: user.userId,
      phoneNumber: formattedPhoneNumber,
      name: name,
      userType: usertype,
      newEmail: email,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${ENDPOINT}/${Urls.editUser}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Convert the response to JSON
      })
      .then((result) => {
        console.log(result);
        if (result.Success === 1) {
          toast.success(result.Message, {
            icon: "👏",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          setTimeout(() => {
            navigate("/users");
          }, 2000);
        } else {
          toast.error(result.Message || "Update failed!", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("Update failed!", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      });

  };


  const confirmDelete = () => {
    setConfirmOpen(true);
  };

  const handleDelete = () => {
    const myHeaders = new Headers();
    let token = localStorage.getItem("authToken");

    myHeaders.append("authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      userId: user.userId,
    });

    const requestOptions = {
      method: "POST", // Change from DELETE to POST
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${ENDPOINT}/${Urls.deleteUser}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json(); // Parse the response as JSON
      })
      .then((result) => {
        console.log(result);
        if (result.Success === 1) {
          toast.success(
            result.Message, // Show the message from the result
            {
              icon: "👏",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            }
          );
          setTimeout(() => {
            navigate("/users");
          }, 2000);
        } else {
          toast.error("Deletion failed!");
        }
      })
      .catch((error) => {
        console.error("error", error);
        toast.error("Deletion failed!");
      });
  };

  const handleCopy = () => {
    const textToCopy = `UserId: ${user.userId}\nPassword: ${user.rawPassword}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("UserId and Password copied to clipboard");
        // alert('UserId and Password copied to clipboard');
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleDownload = () => {
    const myHeaders = new Headers();
    let token = localStorage.getItem("authToken");

    myHeaders.append("authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const startDate = fromDate ? fromDate.toISOString().split("T")[0] : "";
    const endDate = toDate ? toDate.toISOString().split("T")[0] : "";

    fetch(
      `${ENDPOINT}/${Urls.exportAttendance}?userId=${user.userId}&startDate=${startDate}&endDate=${endDate}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "attendance.xlsx"); // or the appropriate filename
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        handleClose();
      })
      .catch((error) => console.log("error", error));

  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmYes = () => {
    setConfirmOpen(false);
    handleDelete();
  };

  return (
    <>
      <UserNavbarUpate />
      <Toaster />
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 4,
          padding: 2,
          paddingBottom: 2,
          gap: 2,
          background: "#E8E8E8",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "96%",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontFamily: "CustomFont",
              fontSize: "23px",
              fontWeight: "700",
              lineHeight: "39px",
            }}
            variant="h5"
          >
            Edit User - {user.userId}
          </Typography>
          {userType === 1 && (
            <IconButton
              sx={{
                background: "#3C455E",
                color: "white",
                "&:hover": { background: "#3C455E", color: "white" },
              }}
              onClick={handleClickOpen}
            >
              <DownloadIcon />
            </IconButton>
          )}
        </div>

        <Paper
          elevation={2}
          sx={{ padding: 5, width: "90%", display: "grid", gap: "25px" }}
        >
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={!isEditable} // Disable if not editable
          />
          <TextField
            label="E-mail"
            variant="outlined"
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={!isEditable} // Disable if not editable
          />
          <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
            <PhoneInput
              country={"nl"}
              inputProps={{
                name: "phoneNumber",
                id: "phoneInput",
                className: "form-control",
                style: { width: "100%", fontSize: "Large" },
              }}
              value={phoneNumber}
              onChange={(value) => setPhoneNumber(value)}
              specialLabel={null}
              disabled={!isEditable} // Disable if not editable
            />
          </Box>
          <FormControl fullWidth required>
            <InputLabel>Select Type</InputLabel>
            <Select
              label="Select Type"
              value={usertype}
              onChange={(e) => setUsertype(e.target.value)}
              sx={{ textAlign: "left" }}
              disabled={!isEditable} // Disable if not editable
            >
              <MenuItem value={1}>Admin</MenuItem>
              <MenuItem value={2}>BDE</MenuItem>
              <MenuItem value={3}>Expert</MenuItem>
              <MenuItem value={4}>Expert Manager</MenuItem>
              <MenuItem value={5}>Quality</MenuItem>
              <MenuItem value={6}>Project Assigner</MenuItem>
              <MenuItem value={7}>Client</MenuItem>
            </Select>
          </FormControl>

          {isEditable && (
            <Grid container spacing={2}>
              <Grid item xs>
                <Button
                  onClick={handleSave}
                  type="submit"
                  variant="contained"
                  sx={{
                    background: "#3C455E",
                    mt: 2,
                    width: "20%",
                    justifySelf: "center",
                    fontFamily: "CustomFont",
                    fontSize: "19px",
                    lineHeight: "24px",
                    fontWeight: "700",
                    letterSpacing: "0.5px",
                  }}
                >
                  Save
                </Button>
              </Grid>
              {isDeletable ? (
                <Grid item>
                  {userType === 1 && (
                    <Typography
                      sx={{ mt: 2, cursor: "pointer" }}
                      onClick={confirmDelete}
                    >
                      <span
                        style={{
                          fontFamily: "CustomFont",
                          fontSize: "18px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "0.5px",
                        }}
                      >
                        Delete
                      </span>
                    </Typography>
                  )}
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          )}
        </Paper>

        <Grid sx={{ marginTop: "3%" }}>
          <Typography>
            <span
              style={{
                fontFamily: "CustomFont",
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "24px",
                color: " #000000",
              }}
            >
              <b>User Id:</b>
            </span>
            <span
              style={{
                fontFamily: "CustomFont",
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "24px",
                color: " #000000",
              }}
            >
              {user.userId}
            </span>
          </Typography>
          <Typography>
            <span
              style={{
                fontFamily: "CustomFont",
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "24px",
                color: " #000000",
              }}
            >
              <b>Password:</b>
            </span>
            <span
              style={{
                fontFamily: "CustomFont",
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "24px",
                color: " #000000",
              }}
            >
              {user.rawPassword}
            </span>
          </Typography>
        </Grid>
        <Button
          variant="text"
          sx={{
            width: "254px",
            height: "46px",
            background: "#FFFFFF",
            gap: "0px",
            opacity: "1",
            "&:hover": {
              background: "#FFFFFF", // Keep the background the same
              opacity: "1", // Keep the opacity the same
              boxShadow: "none", // Remove any box-shadow if present
            },
          }}
          onClick={handleCopy}
        >
          <span
            style={{
              fontFamily: "CustomFont",
              fontSize: "18px",
              color: "#00000082",
            }}
          >
            Tap To Copy Here
          </span>
        </Button>

        <Dialog
          open={open}
          onClose={handleClose}
        >
          <DialogTitle
            sx={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "bold" }}
          >
            Download Attendance - {user.name}
          </DialogTitle>
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{ maxWidth: 400, margin: "0 auto" }}
                >
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="From"
                      value={fromDate}
                      onChange={(newValue) => setFromDate(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="To"
                      value={toDate}
                      onChange={(newValue) => setToDate(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </LocalizationProvider>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleDownload}
              color="primary"
              variant="contained"
              sx={{
                width: "100%",
                maxWidth: 300,
                marginBottom: 2,
                backgroundColor: "rgb(60, 69, 94)",
                "&:hover": {
                  backgroundColor: "rgb(50, 59, 84)",
                },
              }}
            >
              Download
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Dialog
        open={confirmOpen}
        onClose={handleConfirmClose}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this user?</DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmYes} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
